/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { RiArrowRightSLine, RiUserSettingsLine, RiTimeLine, RiBriefcaseLine, RiTeamLine, RiFileUserLine } from "react-icons/ri"

const GuidesPage = () => {
  return (
    <Layout>
      <SEO 
        title="HR Guides | Best Practices for HR Management"
        description="Comprehensive HR guides and best practices for performance reviews, recruitment, employee engagement, and HR strategy."
        keywords={[
          "HR management guides",
          "performance review best practices",
          "employee engagement guide",
          "recruiting strategies",
          "HR policy templates"
        ]}
      />
      
      <div sx={{
        bg: 'primaryMuted',
        pt: [4, 5],
        pb: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <h1 sx={{
            fontSize: ['2rem', '2.5rem'],
            fontWeight: 700,
            textAlign: 'center',
            mb: 3,
            color: 'primary'
          }}>
            HR Guides & Best Practices
          </h1>
          
          <p sx={{
            fontSize: '1.1rem',
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mb: 5,
            lineHeight: 1.5,
            color: 'text'
          }}>
            Comprehensive guides to help you implement best practices in your organization.
            From performance reviews to recruitment strategies, these resources will help you
            build effective HR processes and policies.
          </p>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
            gap: 4,
            mb: 5
          }}>
            {/* Performance Reviews Guide */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/guides/performance-reviews" sx={{
                textDecoration: 'none',
                color: 'text'
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiUserSettingsLine />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  Performance Reviews
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Best practices for conducting effective performance reviews and feedback sessions.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem'
                }}>
                  Read Guide <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
            
            {/* Recruitment Guide */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/guides/recruitment-hiring" sx={{
                textDecoration: 'none',
                color: 'text'
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiBriefcaseLine />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  Recruitment & Hiring
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Strategies for attracting top talent and streamlining your recruitment process.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem'
                }}>
                  Read Guide <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
            
            {/* Employee Onboarding Guide */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/guides/employee-onboarding" sx={{
                textDecoration: 'none',
                color: 'text'
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiFileUserLine />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  Employee Onboarding
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Creating an effective onboarding program to set new hires up for success.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem'
                }}>
                  Read Guide <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
            
            {/* Employee Engagement Guide */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/guides/employee-engagement" sx={{
                textDecoration: 'none',
                color: 'text'
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiTeamLine />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  Employee Engagement
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Strategies to increase employee satisfaction, engagement, and retention.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem'
                }}>
                  Read Guide <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
            
            {/* HR Compliance Guide */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/guides/hr-compliance" sx={{
                textDecoration: 'none',
                color: 'text'
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiFileUserLine />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  HR Compliance
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Essential compliance requirements and risk management for HR professionals.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem'
                }}>
                  Read Guide <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
            
            {/* Remote Work Guide */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/guides/remote-work" sx={{
                textDecoration: 'none',
                color: 'text'
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiTimeLine />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  Remote Work
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Best practices for managing remote and hybrid teams effectively.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem'
                }}>
                  Read Guide <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
          </div>
          
          <div sx={{
            bg: 'white',
            p: 4,
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <h3 sx={{
              fontSize: '1.25rem',
              fontWeight: 600,
              mb: 2,
              color: 'primary'
            }}>
              Need Customized HR Guidance?
            </h3>
            <p sx={{
              fontSize: '0.95rem',
              color: 'text',
              mb: 3,
              lineHeight: 1.5
            }}>
              Our HR consultants can provide personalized advice for your organization's specific needs. 
              Schedule a consultation to discuss your HR challenges and goals.
            </p>
            <Link to="/contact" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              bg: 'primary',
              color: 'white',
              py: 2,
              px: 3,
              borderRadius: '6px',
              fontSize: '0.95rem',
              fontWeight: 600,
              textDecoration: 'none',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                color: 'white'
              }
            }}>
              Contact Our HR Consultants <RiArrowRightSLine />
            </Link>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div sx={{
        maxWidth: '800px',
        mx: 'auto',
        py: 5,
        px: [3, 0]
      }}>
        <h2 sx={{ 
          fontSize: ['1.5rem', '1.65rem'],
          mb: 4,
          color: 'primary',
          textAlign: 'center'
        }}>Frequently Asked Questions About HR Guides</h2>

        <div sx={{
          display: 'grid',
          gap: 4
        }}>
          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>What topics do these HR guides cover?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>Our guides cover a wide range of essential HR topics, including performance management, recruitment and hiring strategies, effective employee onboarding, boosting employee engagement, ensuring HR compliance, and best practices for managing remote teams.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>Who are these guides intended for?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>These guides are designed for HR professionals, managers, business owners, and anyone involved in people management who wants to implement best practices and improve HR processes within their organization, regardless of company size.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>Are the best practices in the guides suitable for all industries?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>While the fundamental principles and best practices discussed are broadly applicable, some specific strategies might need adaptation based on your industry, company culture, and local regulations. We always recommend considering your unique context.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>How up-to-date is the information in these guides?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>We strive to keep our guides current with the latest HR trends, research, and compliance updates. They are reviewed and updated periodically to ensure the information remains relevant and actionable for modern workplaces.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>Can I get personalized HR advice beyond these guides?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>Yes! While our guides offer comprehensive overviews, we understand that every organization has unique challenges. Our experienced HR consultants are available for personalized consultations to address your specific needs. You can contact us through the link provided on this page.</p>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default GuidesPage 